<template>
  <div class="container">
    <template v-if="RetMsg">
      <img class="bugIcon" src="@/assets/images/bug.png" />
      <p class="errorMessage">{{RetMsg}}</p>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      RetMsg: ''
    }
  },
  computed: {
    accesstoken() {
      return this.$route.query.accesstoken;
    }
  },
  created() {
    console.log(this.accesstoken);
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      if(this.accesstoken) {
        this.$axios.post('/Api/Api/Web/D365SSO/GetUser', {
          accesstoken: this.accesstoken
        }).then(res => {
            if (res.RetCode == '10000') {
              // console.log(res.Data)
              sessionStorage.setItem("UserInfo", JSON.stringify(res.Data));
              this.$router.push('index');
            } else if(res.RetCode == '2002') {
              this.RetMsg = '您暂无权限访问该页面';
            } else {
              this.RetMsg = res.RetMsg;
            }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  box-sizing: border-box;
}
.bugIcon {
  display: block;
  width: 120px;
  margin: 100px auto 20px;
}
.errorMessage {
  font-size: 14px;
  display: block;
  text-align: center;
}
</style>